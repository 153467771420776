.TextContainer {
  background-color: #7e7477ff;
  width: fit-content;
  margin: 20px;
  padding: 20px;
  text-align: center;
}
.VideoContainer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 480px) {
  /* Your CSS styles for Mobile L */
  .VideoContainer {
    max-width: 100%; /* Adjust to your preference */
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
  }
}
