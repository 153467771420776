/* TestimonialCarousel CSS*/
.carouselItem {
  position: relative;
  max-height: fit-content;
  background-color: #7e7477ff;
  padding: 20px;
  transition: max-height 1s ease;
}
.carouselHeader {
  font-family: "Satisfy", cursive;
  color: white;
  font-size: 1.8rem;
  text-align: center;
}
.carouselText {
  color: white;
  font-family: "Work Sans", sans-serif;
  margin: 20px;
  font-size: 1.2rem;
  text-align: center;
}
.carouselText.link {
  color: lightblue;
  text-decoration: underline;
}
/* UpcomingEvents CSS*/

/*Demnisons for each image (Changed for the purpouse of 2024 update original was 5 and 5)*/
.eventCard {
  padding: 10px;
  margin: 10px;
}

.eventImage {
  height: 100%;
  width: 100%;
}
.imageContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 100%; /* Adjust based on the aspect ratio of your images (e.g., 4:3 would be 75%) */
}

.imageContainer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will ensure the image covers the entire container without distortion */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 23px;
  transition: opacity 0.3s;
}

.overlay:hover {
  opacity: 1;
}

.title {
  font-family: "Satisfy", cursive;
  font-size: 2rem;
  margin-top: 10px; /* Original was 150, but since no overlay no need */
  margin-bottom: 10px;
  text-align: center;
}

.description {
  font-family: "Roboto Condensed", sans-serif;
  color: white;
  text-decoration: wavy;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 20px;
  overflow: auto;
}

/* Mobile L Dimensions */
@media only screen and (max-width: 480px) {
  /* TestimonialCarousel CSS*/

  .carouselHeader {
    font-family: "Satisfy", cursive;
    color: white;
    font-size: 1.2rem;
    text-align: center;
  }
  .carouselText {
    color: white;
    font-family: "Work Sans", sans-serif;
    margin: 20px;
    font-size: 0.8rem;
    text-align: center;
  }
  .carouselText.link {
    color: lightblue;
    text-decoration: underline;
  }
  /* UpcomingEvents CSS*/
  .description {
    overflow: scroll;
    font-size: 1.2rem;
  }
  .imageContainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* This will ensure the image covers the entire container without distortion */
  }
}
