.round {
  border-radius: 50%;
  height: 650px;
  vertical-align: middle;
}

@media only screen and (max-width: 480px) {
  .round {
    border-radius: 50%;
    height: 350px;
  }
}
