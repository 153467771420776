.header {
  text-align: center;
  color: #edbcccff;
}

@media only screen and (max-width: 480px) {
  .header {
    text-align: left;
  }
}
