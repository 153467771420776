.cardImg {
  height: 500px;
}
.cardStyles {
  width: 90%;
  margin: auto;
  margin-bottom: 40px;
}

.cardText {
  color: #63326eff;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  padding: 5px;
  text-align: center;
}
.cardTitle {
  color: #63326eff;
  font-family: "Satisfy", cursive;
  font-size: 22px;
  text-align: center;
}
@media only screen and (max-width: 480px) {
  .cardImg {
    height: 375px;
  }
  .cardStyles {
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
  }

  .cardText {
    color: #63326eff;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 12px;
    padding: 5px;
    text-align: center;
  }
  .cardTitle {
    color: #63326eff;
    font-family: "Satisfy", cursive;
    font-size: 22px;
    text-align: center;
  }
}
