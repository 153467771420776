.aboutText {
  color: white;
  font-family: "Work Sans", sans-serif;
  font-size: 17px;
  margin: 13px;
}

.aboutText.link:hover {
  opacity: 70%;
}

.titleImg {
  width: 60%;
  height: 60%;
  padding: 2rem;
}

@media only screen and (max-width: 480px) {
  .aboutText {
    font-size: 13px;
    margin: 10px;
  }

  .titleImg {
    height: fit-content;
    width: 100%;
    height: 100%;
    padding: 2rem;
  }
}
