/* FADE IN ANIMATION */
.FadeInAnimation {
  opacity: 0; /* Initially set opacity to 0 */
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards; /* Keep the final state of the animation after it ends */
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Start with opacity 0 */
  }
  to {
    opacity: 1; /* End with opacity 1 */
  }
}
/* FADE IN ANIMATION */

/* RISING IN ANIMATION */
.RisingInAnimation {
  transform: translateY(100px); /* Initially set translateY to 100px */
  animation-name: riseIn;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards; /* Keep the final state of the animation after it ends */
}

@keyframes riseIn {
  from {
    transform: translateY(100px); /* Start with translateY at 100px */
  }
  to {
    transform: translateY(0); /* End with translateY at 0 */
  }
}
/* RISING IN ANIMATION */
