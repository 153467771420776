.carousel {
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.img {
  width: 100%;
  height: 1000px;
}
@media only screen and (max-width: 480px) {
  .carousel {
    width: 90%;
  }
  .img {
    height: 400px;
  }
}
