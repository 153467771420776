.whoImg {
  width: 100%;
  height: 80%;
  padding: 5px;
  padding-bottom: 15px;
}

.whoText {
  font-size: 1.7rem;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 10px;
}

/* Mobile L Dimensions */
@media only screen and (max-width: 480px) {
  .whoImg {
    height: 200px;
    padding: 5px;
    padding-bottom: 15px;
  }
  .whoText {
    font-size: 1.2rem;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px;
  }
}
