/* CSS HEX 
--white: #ffffffff;
--cambridge-blue: #7daa92ff;
--finn: #63326eff;
--orchid-pink: #edbcccff;
--taupe-gray: #7e7477ff; 
*/

.button {
  background-color: #7e7477ff;
  font-family: "Work Sans", sans-serif;
  margin: auto;
  width: fit-content;
  height: fit-content;
  color: white;
  font-size: 1.2rem;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button.lg {
  font-family: "Satisfy", cursive;
  font-size: 2rem;
}

.button:hover {
  background-color: black;
  color: white;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.header {
  text-align: center;
  font-family: "Satisfy", cursive;
  font-size: 45px;
  color: #7e7477ff;
  padding: 5px;
  margin: 10px;
}

.text-box {
  border: 3px solid #7e7477ff; /* Border color and thickness */
  padding: 10px; /* Add padding for spacing */
  width: 85%; /* Set the desired width */
  margin: 0 auto; /* Center the text box horizontally */
  font-size: 18px;
}

.title {
  text-align: center;
  font-family: "Satisfy", cursive;
  font-size: 70px;
  color: #edbcccff;
  padding: 10px;
  margin: 30px;
}

img {
  padding: 20px;
  border-radius: initial;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
p {
  color: #63326eff;
  padding: 10px;
  margin: 15px;
  font-family: "Work Sans", sans-serif;
  text-align: center;
}

/* Mobile L Dimensions */
@media only screen and (max-width: 480px) {
  .header {
    font-size: 25px;
  }

  .text-box {
    border: 3px solid #7e7477ff; /* Border color and thickness */
    padding: 5px; /* Add padding for spacing */
    width: 90%; /* Set the desired width */
    margin: 0 auto; /* Center the text box horizontally */
    font-size: 12px;
  }
  .title {
    font-size: 38px;
    padding: 10px;
    margin: 20px;
  }
}
