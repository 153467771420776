.caption {
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  color: #7e7477ff;
  margin: 10px;
}
.carousel {
  width: 80%;
  display: block;
  margin: auto;
}
.carouselImg {
  height: 1000px;
  width: 100%;
}
.eventContainer {
  position: relative;
  overflow: hidden;
}

.eventImg {
  width: 100%;
  height: 250px;
  border-radius: 50%;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Adjust based on your layout */
}

@media only screen and (max-width: 480px) {
  .carousel {
    width: 100%;
    display: block;
    margin: auto;
  }
  .carouselImg {
    height: 400px;
    width: 100%;
  }
  .spinnerContainer {
    height: 200px; /* Adjust based on your layout */
  }
}
