.cardStyles {
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
}
.cardText {
  font-size: 22px;
  font-family: "Roboto Condensed", sans-serif;
}
.cardTitle {
  text-align: center;
  font-family: "Satisfy", cursive;
  font-size: 25px;
}
.carouselItem {
  position: relative;
  max-height: fit-content;
  background-color: inherit;
  padding: 90px;
  transition: max-height 1s ease;
}

@media only screen and (max-width: 480px) {
  .cardText {
    font-size: 12px;
    font-family: "Roboto Condensed", sans-serif;
  }
  .carouselItem {
    position: relative;
    max-height: fit-content;
    background-color: inherit;
    padding: 20px;
    transition: max-height 1s ease;
  }
}
